import React from 'react'
import PropTypes from 'prop-types'

export default class FormBigTextInput extends React.Component {
    id() {
        return this.props.id
    }

    label() {
        return this.props.label
    }

    placeholder() {
        return this.props.placeholder
    }

    onChange(value) {
        return this.props.onChange(value)
    }

    render() {
        const id = this.id()
        return (
            <div className="big-text-input-container">
                <label className="form-label" htmlFor={id}>
                    {this.label()}
                </label>
                <textarea
                    id={id}
                    name={id}
                    rows="15"
                    placeholder={this.placeholder()}
                    className="form-big-text-input"
                    onChange={(event) => this.onChange(event.target.value)}
                />
            </div>
        )
    }
}

FormBigTextInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
}

FormBigTextInput.defaultProps = {
    placeholder: '',
}

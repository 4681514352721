import React from 'react'
import Button from '../components/Button'
import PropTypes from 'prop-types'
import { HOME_PATH } from '../routes'
import { Link } from 'react-router-dom'
import * as animatedKittie from '../media/animations/animated-kittie.json'
import Lottie from 'react-lottie'

export default class NotFound extends React.Component {
    texts() {
        return this.props.texts
    }

    render() {
        return (
            <div className="not-found">
                <div className="not-found-container">
                    <div className="img-column">
                        <Lottie options={{ animationData: animatedKittie }} />
                    </div>

                    <div className="error-column">
                        <h2 className="error-title">
                            {this.texts()['errorFirstTitle']}
                        </h2>
                        <h2 className="error-title">
                            {this.texts()['errorSecondTitle']}
                        </h2>
                        <p className="error-description">
                            {this.texts()['errorDescription']}
                        </p>

                        <div className="error-button-container">
                            <Link to={HOME_PATH}>
                                <Button
                                    theme="blue"
                                    title={this.texts()['errorButton']}
                                ></Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

NotFound.propTypes = {
    texts: PropTypes.object.isRequired,
}

import React from 'react'
import arrowIcon from '../media/icons/arrow.svg'
import PropTypes from 'prop-types'

export class ScrollDownButton extends React.Component {
    sectionIdToScroll() {
        return this.props.sectionIdToScroll
    }

    handleScroll() {
        const section = document.getElementById(this.sectionIdToScroll())
        section.scrollIntoView({behavior: "smooth"})
    }

    render() {
        return (
            <button
                className="scroll-down-button-container"
                onClick={() => this.handleScroll()}
            >
                <span className="scroll-down-button-text">Scroll Down</span>
                <img
                    className="scroll-down-button-icon"
                    src={arrowIcon}
                    alt="Scroll down button"
                />
            </button>
        )
    }
}

ScrollDownButton.propTypes = {
    sectionIdToScroll: PropTypes.string.isRequired,
}

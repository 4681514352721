import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

export default class SectionLink extends React.Component {
    to() {
        return this.props.to
    }

    title() {
        return this.props.title
    }

    onClick() {
        return this.props.onClick()
    }

    render() {
        return (
            <Link
                to={this.to()}
                className="section-link"
                onClick={() => this.onClick()}
            >
                {this.title()}
            </Link>
        )
    }
}

SectionLink.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}

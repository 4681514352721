import React from 'react'
import Button from '../components/Button'
import FormTextInput from '../components/form/FormTextInput'
import FormBigTextInput from '../components/form/FormBigTextInput'
import FormRadioInput from '../components/form/FormRadioInput'
import PropTypes from 'prop-types'
import * as emailjs from '@emailjs/browser'
import Modal from '../components/Modal'
import thankYouIcon from '../media/icons/thank-you.svg'
import errorIcon from '../media/icons/error.svg'
import isEmailValid from "../helpers/utils";
import ReCAPTCHA from "react-google-recaptcha";


export default class ContactUs extends React.Component {

  NAME_FIELD = 'name'
  JOB_FIELD = 'job'
  PROJECT_CONTEXT_FIELD = 'projectContext'
  PROJECT_DEADLINE_FIELD = 'projectDeadline'
  EMAIL_FIELD = 'email'

  constructor(props) {
    super(props)
    this.state = {
      [this.NAME_FIELD]: '',
      [this.JOB_FIELD]: '',
      [this.PROJECT_CONTEXT_FIELD]: '',
      [this.PROJECT_DEADLINE_FIELD]: '',
      [this.EMAIL_FIELD]: '',
      modalTitle: '',
      modalIcon: '',
      modalDescription: '',
      modalButtonTitle: '',
      isCaptchaValid: false,
      isModalOpen: false,
      sendingEmail: false,
    }
    this._refCaptcha = React.createRef();
  }

  successModalStateFor(texts) {
    return {
      modalTitle: texts['formSuccessTitle'],
      modalIcon: thankYouIcon,
      modalDescription: texts['formSuccessDescription'],
      modalButtonTitle: '',
      isModalOpen: true,
    }
  }

  errorModalStateFor(texts) {
    return {
      modalTitle: texts['formErrorTitle'],
      modalIcon: errorIcon,
      modalDescription: '',
      modalButtonTitle: texts['formErrorButton'],
      isModalOpen: true,
    }
  }

  isFormValid() {
    return this.state[this.NAME_FIELD] !== '' &&
      this.state[this.PROJECT_CONTEXT_FIELD] !== '' &&
      this.state[this.PROJECT_DEADLINE_FIELD] !== '' &&
      this.state[this.EMAIL_FIELD] !== '' &&
      isEmailValid(this.state[this.EMAIL_FIELD]) &&
      this.state.isCaptchaValid
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({sendingEmail: true})

    const texts = this.texts();
    const token = this._refCaptcha.current.getValue();

    const params = {
      'name': this.state[this.NAME_FIELD],
      'job': this.state[this.JOB_FIELD],
      'projectContext': this.state[this.PROJECT_CONTEXT_FIELD],
      'projectDeadline': this.state[this.PROJECT_DEADLINE_FIELD],
      'email': this.state[this.EMAIL_FIELD],
      'g-recaptcha-response': token,
    };

    emailjs
      .send(
        'service_h1vq2gr',
        'template_qzvns8k',
        params, {
          publicKey: '1Tqjnnmcy-mu4IHEA',
        })
      .then(
        () => {
          this.setState(this.successModalStateFor(texts))
        },
        () => {
          this.setState(this.errorModalStateFor(texts))
        },
      ).finally(
      () => {
        this._refCaptcha.current.reset();
        this.setState({isCaptchaValid: false, sendingEmail: false})
      }
    )
  }

  handleInputChange(id, value) {
    this.setState({[id]: value})
  }

  texts() {
    return this.props.texts
  }

  modalTitle() {
    return this.state.modalTitle
  }

  isModalOpen() {
    return this.state.isModalOpen
  }

  modalDescription() {
    return this.state.modalDescription
  }

  modalButtonTitle() {
    return this.state.modalButtonTitle
  }

  modalIcon() {
    return this.state.modalIcon
  }

  handleModalClose() {
    this.setState({isModalOpen: false})
  }

  render() {
    const texts = this.texts()

    return (
      <div>
        <Modal
          id="contact-us-modal"
          icon={this.modalIcon()}
          title={this.modalTitle()}
          isOpen={this.isModalOpen()}
          description={this.modalDescription()}
          buttonTitle={this.modalButtonTitle()}
          onClose={() => this.handleModalClose()}
        />
        <div className="contact-us">
          <h2 className="contact-us-title">{texts['formTitle']}</h2>
          <form className="contact-us-form">
            <p className="form-greetings">
              {texts['formGreetings']}
            </p>
            <FormTextInput
              id={this.NAME_FIELD}
              label={texts['formNameField']}
              placeholder={texts['formNamePlaceholder']}
              onChange={(value) =>
                this.handleInputChange(this.NAME_FIELD, value)
              }
            />
            <FormTextInput
              id={this.JOB_FIELD}
              label={texts['formJobField']}
              placeholder={texts['formJobPlaceholder']}
              onChange={(value) =>
                this.handleInputChange(this.JOB_FIELD, value)
              }
            />
            <FormBigTextInput
              id={this.PROJECT_CONTEXT_FIELD}
              label={texts['formProjectContextField']}
              placeholder={texts['formProjectContextPlaceholder']}
              onChange={(value) =>
                this.handleInputChange(
                  this.PROJECT_CONTEXT_FIELD,
                  value,
                )
              }
            />
            <FormRadioInput
              id={this.PROJECT_DEADLINE_FIELD}
              label={texts['formProjectDeadlineField']}
              options={texts['formProjectDeadlineOptions']}
              onChange={(value) =>
                this.handleInputChange(
                  this.PROJECT_DEADLINE_FIELD,
                  value,
                )
              }
            />
            <FormTextInput
              id={this.EMAIL_FIELD}
              label={texts['formEmailField']}
              placeholder={texts['formEmailPlaceholder']}
              onChange={(value) =>
                this.handleInputChange(this.EMAIL_FIELD, value)
              }
            />
            <ReCAPTCHA ref={this._refCaptcha}
                       onChange={() => this.setState({isCaptchaValid: true})}
                       sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}/>
            <div className="send-button-container">
              <Button
                title={this.state.sendingEmail ? texts['formButtonSending'] : texts['formButton']}
                disabled={!this.isFormValid() || this.state.sendingEmail}
                theme="blue"
                onClick={(event) => this.handleSubmit(event)}
              />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

ContactUs.propTypes = {
  texts: PropTypes.any.isRequired,
}

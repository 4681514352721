import React from 'react'
import Project from '../../components/Project'
import {BUENBIT_PATH, COMPUTER_VISION_PATH, FINAER_PATH, NEXT_ROLL_PATH} from '../../routes'
import secondProjectOverlayImage from "../../media/overlay_second_project.webp";
import thirdProjectOverlayImage from "../../media/overlay_third_project.webp";
import firstProjectOverlayImage from "../../media/overlay_first_project.webp";

export default class ToyotaProject extends React.Component {
    texts() {
        return this.props.texts
    }

    image() {
        return this.props.image
    }

  solutionsTexts() {
    return this.texts()['solutions']
  }

    otherProjects() {
        return [
            {
                title: this.solutionsTexts()['solutionsBuenbitProjectTitle'],
                content: this.solutionsTexts()['solutionsBuenbitProjectContent'],
                image: firstProjectOverlayImage,
                path: BUENBIT_PATH,
                button: this.solutionsTexts()['solutionsCardButton'],
            },
            {
                title: this.solutionsTexts()['solutionsFinaerProjectTitle'],
                content: this.solutionsTexts()['solutionsFinaerProjectContent'],
                image: secondProjectOverlayImage,
                path: FINAER_PATH,
                button: this.solutionsTexts()['solutionsCardButton'],
            },
            {
                title: this.solutionsTexts()['solutionsNextRollProjectTitle'],
                content:
                  this.solutionsTexts()['solutionsNextRollProjectContent'],
                image: thirdProjectOverlayImage,
                path: NEXT_ROLL_PATH,
                button: this.solutionsTexts()['solutionsCardButton'],
            },
        ]
    }

    render() {
        return (
            <Project
                texts={this.texts()}
                projectImg={this.image()}
                otherProjects={this.otherProjects()}
                projectAreaPath={COMPUTER_VISION_PATH}
            />
        )
    }
}

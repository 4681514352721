import React from 'react'
import {
    BUENBIT_PATH,
    COMPUTER_VISION_PATH,
    CRYPTOGRAPHY_PATH,
    FINAER_PATH,
    NEXT_ROLL_PATH,
} from '../../routes'
import Service from '../../components/Service'
import firstProjectOverlayImage from '../../media/overlay_first_project.webp'
import secondProjectOverlayImage from '../../media/overlay_second_project.webp'
import thirdProjectOverlayImage from '../../media/overlay_third_project.webp'

export default class SoftwareServices extends React.Component {
    texts() {
        return this.props.texts
    }

    solutionsTexts() {
        return this.texts()['solutions']
    }

    otherSolutions() {
        const texts = this.solutionsTexts()
        return [
            {
                title: texts['computerVisionSolutionTitle'],
                content: texts['computerVisionSolutionContent'],
                pillNames: texts['computerVisionSolutionPillNames'],
                buttonTitle: texts['solutionsCardButton'],
                path: COMPUTER_VISION_PATH,
            },
            {
                title: texts['cryptographySolutionTitle'],
                content: texts['cryptographySolutionContent'],
                pillNames: texts['cryptographySolutionPillNames'],
                buttonTitle: texts['solutionsCardButton'],
                path: CRYPTOGRAPHY_PATH,
            },
        ]
    }

    otherProjects() {
        const solutionsTexts = this.solutionsTexts()
        return [
            {
                title: solutionsTexts['solutionsBuenbitProjectTitle'],
                content: solutionsTexts['solutionsBuenbitProjectContent'],
                image: firstProjectOverlayImage,
                path: BUENBIT_PATH,
                button: solutionsTexts['solutionsCardButton'],
            },
            {
                title: solutionsTexts['solutionsFinaerProjectTitle'],
                content: solutionsTexts['solutionsFinaerProjectContent'],
                image: secondProjectOverlayImage,
                path: FINAER_PATH,
                button: solutionsTexts['solutionsCardButton'],
            },
            {
                title: solutionsTexts['solutionsNextRollProjectTitle'],
                content: solutionsTexts['solutionsNextRollProjectContent'],
                image: thirdProjectOverlayImage,
                path: NEXT_ROLL_PATH,
                button: solutionsTexts['solutionsCardButton'],
            },
        ]
    }

    render() {
        return (
            <Service
                businessUnitDetailClassName="detailed-services-presentation-section"
                texts={this.texts()}
                otherSolutions={this.otherSolutions()}
                otherProjects={this.otherProjects()}
            />
        )
    }
}

import React from 'react'
import PropTypes from 'prop-types'

export default class FormRadioInput extends React.Component {
    id() {
        return this.props.id
    }

    label() {
        return this.props.label
    }

    options() {
        return this.props.options
    }

    onChange(value) {
        return this.props.onChange(value)
    }

    render() {
        const id = this.id()
        return (
            <div className="radio-input-container">
                <label className="form-label" htmlFor={id}>
                    {this.label()}
                </label>
                {this.options().map((option) => (
                    <div key={option.id}>
                        <input type="radio"
                               id={option.id}
                               value={option.label}
                               name={id}
                               onChange={(event) => this.onChange(event.target.value)}/>
                        <label className="small-form-label" htmlFor={option.id}>
                            {option.label}
                        </label>
                    </div>
                ))}
            </div>
        )
    }
}

FormRadioInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({id: PropTypes.string, label: PropTypes.string}),
    ).isRequired,
}

import React from 'react'
import '../styles/styles.scss'
import '../styles/media-queries.scss'
import PropTypes from 'prop-types'
import Button from '../components/Button'
import {ScrollDownButton} from '../components/ScrollDownButton'
import AnimatedCard from '../components/AnimatedCard'
import {Link} from 'react-router-dom'
import SomosImg from '../media/eryx-team/somos.webp'
import LeftShape from '../media/rounded-shape.svg'
import RightShape from '../media/sliced-shape.svg'
import Lottie from 'react-lottie'
import * as animatedIcon1 from '../media/animations/animated-icon-1.json'
import * as animatedIcon2 from '../media/animations/animated-icon-2.json'
import * as animatedIcon3 from '../media/animations/animated-icon-3.json'
import {CONTACT_PATH, SOLUTIONS_PATH, US_PATH} from '../routes'
import WordsVerticalRotator from '../components/WordsVerticalRotator'
import LogosCarrousel from '../components/LogosCarrousel'

export default class Home extends React.Component {
    PRESENTATION_SECTION_ID = 'presentation'
    HERO_URL = "https://storage.googleapis.com/eryx-web/hero-video.mp4"
    HERO_MOBILE_URL = "https://storage.googleapis.com/eryx-web/hero-vertical-video.mp4"

    constructor(props) {
        super(props)
        this.state = {
            cardsIds: ['first-card', 'second-card', 'third-card'],
            scrollOnCard: undefined,
            isMobile: window.innerWidth < 768,
            heroVideoLoading: true,
            heroUrl: window.innerWidth < 768 ?  this.HERO_MOBILE_URL : this.HERO_URL
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', () => this.handleScroll());
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.handleScroll())
    }

    texts() {
        return this.props.texts
    }

    dreamTeamAnimation() {
        return this.props.dreamTeamAnimation
    }

    cardsIds() {
        return this.state.cardsIds
    }

    scrollOnCard() {
        return this.state.scrollOnCard
    }

    handleScroll() {
        const cardsIds = this.cardsIds()
        const scrollOnCard = cardsIds.find((cardId) => {
            const cardElement = document.getElementById(cardId)
            if (cardElement) {
                const rect = cardElement.getBoundingClientRect()
                return rect.top >= 0 && rect.bottom <= window.innerHeight
            }
            return false
        })
        this.setState({
            scrollOnCard: scrollOnCard
        })
    }

    isMobile() {
        return this.state.isMobile
    }

    renderHero(texts) {
        return (
            <section className={this.heroVideoLoading ? 'home-section hero loading' : 'home-section hero'}>
                <video className="hero-video" autoPlay loop muted
                       onCanPlay={() => this.setState({ heroVideoLoading: false })}>>
                    <source
                        src={this.state.heroUrl}
                        type="video/mp4"
                    />
                </video>

                <div className="hero-container">
                    <h1 className="home-title">
                        {texts['heroTitleFirstLine']}
                        <br />
                        {texts['heroTitleSecondLine']}
                    </h1>
                    <ScrollDownButton
                        sectionIdToScroll={this.PRESENTATION_SECTION_ID}>
                    </ScrollDownButton>
                </div>
            </section>
        )
    }

    renderPresentationSection(texts) {
        return (
            <section
                className="home-section presentation"
                id={this.PRESENTATION_SECTION_ID}
            >
                <div className="presentation-container">
                    <h2>{texts['presentationTitle']}</h2>
                    <div className="presentation-button">
                        <Link to={SOLUTIONS_PATH}>
                            <Button
                                title={texts['presentationButton']}
                            ></Button>
                        </Link>
                    </div>
                </div>
            </section>
        )
    }

    renderWhyUsCard(
        id,
        cardTitle,
        cardDescription,
        animatedIcon
    ) {
        return (
            <div className="why-us-card-container" id={id}>
                <div className="why-us-card-container-row">
                    <div className="why-us-card">
                        <AnimatedCard
                            description={cardDescription}
                            title={cardTitle}
                            animatedIcon={animatedIcon}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderWhyUsSection(texts) {
        const titleFirstLine = texts['whyUsTitleFirstLine']
        const titleSecondLine = texts['whyUsTitleSecondLine']
        return (
            <section className="home-section why-us">
                <h2 className="why-us-title">
                    {titleFirstLine}
                    <br />
                    {titleSecondLine}
                </h2>
                {this.renderWhyUsCard(
                    'first-card',
                    texts['whyUsFirstCardTitle'],
                    texts['whyUsFirstCardDescription'],
                    animatedIcon1
                )}
                {this.renderWhyUsCard(
                    'second-card',
                    texts['whyUsSecondCardTitle'],
                    texts['whyUsSecondCardDescription'],
                    animatedIcon2
                )}
                {this.renderWhyUsCard(
                    'third-card',
                    texts['whyUsThirdCardTitle'],
                    texts['whyUsThirdCardDescription'],
                    animatedIcon3
                )}
            </section>
        )
    }

    renderDreamTeamSection(texts) {
        return (
            <section className="home-section dream-team">
                <div className="dream-team-pills">
                <Lottie options={{ animationData: this.dreamTeamAnimation() }} />
                </div>
                <div className="dream-team-contact">
                    <div>
                        <h2>{texts['dreamTeamTitle']} </h2>
                        <h2 className="dream-team-title">Dream Team</h2>
                    </div>
                    <Link to={CONTACT_PATH}>
                        <div className="dream-team-button">
                            <Button title={texts['dreamTeamButton']} />
                        </div>
                    </Link>
                </div>
            </section>
        )
    }

    renderAboutUsSection(texts) {
        return (
            <section className="home-section about-us">
                <div className="about-us-container">
                    <div className="about-us-img-container">
                        <img className="about-us-img" src={SomosImg} alt="Team" />
                        <img
                            className="left-shape"
                            src={LeftShape}
                            alt="Rounded shape"
                        />
                        <img className="right-shape" src={RightShape} alt="Shape" />
                        <div className="about-us-info-container">
                            <div className="about-us-info">
                                <WordsVerticalRotator
                                    title={texts['aboutUsTitle']}
                                    wordsList={texts['aboutUsWordsList']}
                                />
                                <Link to={US_PATH}>
                                    <Button title={texts['aboutUsButton']} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    renderPartnersSection(texts) {
        return (
            <section className="home-section partners">
                <div style={{ display: 'flex', alignSelf: 'flex-start', marginLeft: '150px', gap: '25px' }}>
                    <div className="icons-row"></div>
                    <div className="icons-row-four-circles"></div>
                </div>
                <h3 className="partners-title">
                    {texts['partnersTitle']}
                </h3>
                <div className="partners-logos">
                    <LogosCarrousel />
                </div>
                <div style={{ display: 'flex', alignSelf: 'flex-end', marginRight: '150px', gap: '25px' }}>
                    <div className="icons-row"></div>
                    <div className="icons-row-four-circles"></div>
                </div>
            </section>
        )
    }

    render() {
        const texts = this.texts()

        return (
            <main className="main-container">
                {this.renderHero(texts)}
                <div className="home-presentation-why-us-container">
                    {this.renderPresentationSection(texts)}
                    {this.renderWhyUsSection(texts)}
                </div>
                {this.renderDreamTeamSection(texts)}
                {this.renderAboutUsSection(texts)}
                {this.renderPartnersSection(texts)}
            </main>
        )
    }
}

Home.propTypes = {
    texts: PropTypes.any.isRequired,
}

import React from 'react'
import nextRollProjectImage from '../../media/clients/next-roll-project.webp'
import {BUENBIT_PATH, FINAER_PATH, SOFTWARE_SERVICES_PATH, TOYOTA_PATH} from '../../routes'
import firstProjectOverlayImage from '../../media/overlay_first_project.webp'
import secondProjectOverlayImage from '../../media/overlay_second_project.webp'
import thirdProjectOverlayImage from '../../media/overlay_third_project.webp'
import Project from '../../components/Project'

export default class NextRollProject extends React.Component {
    texts() {
        return this.props.texts
    }

    solutionsTexts() {
        return this.texts()['solutions']
    }

    otherProjects() {
        return [
            {
                title: this.solutionsTexts()['solutionsFinaerProjectTitle'],
                content: this.solutionsTexts()['solutionsFinaerProjectContent'],
                image: secondProjectOverlayImage,
                path: FINAER_PATH,
                button: this.solutionsTexts()['solutionsCardButton'],
            },
            {
                title: this.solutionsTexts()['solutionsBuenbitProjectTitle'],
                content:
                    this.solutionsTexts()['solutionsBuenbitProjectContent'],
                image: firstProjectOverlayImage,
                path: BUENBIT_PATH,
                button: this.solutionsTexts()['solutionsCardButton'],
            },
            {
                image: thirdProjectOverlayImage,
                title: this.solutionsTexts()['solutionsToyotaProjectTitle'],
                content: this.solutionsTexts()['solutionsToyotaProjectContent'],
                path: TOYOTA_PATH,
                button: this.solutionsTexts()['solutionsProjectButton'],
            },
        ]
    }

    render() {
        return (
            <Project
                texts={this.texts()}
                projectImg={nextRollProjectImage}
                otherProjects={this.otherProjects()}
                projectAreaPath={SOFTWARE_SERVICES_PATH}
            />
        )
    }
}

import React from 'react'
import Service from '../../components/Service'
import { COMPUTER_VISION_PATH, SOFTWARE_SERVICES_PATH } from '../../routes'

export default class CryptographyServices extends React.Component {
    solutionsTexts() {
        return this.texts()['solutions']
    }

    otherSolutions() {
        const texts = this.solutionsTexts()
        return [
            {
                title: texts['softwareSolutionTitle'],
                content: texts['softwareSolutionContent'],
                pillNames: texts['softwareSolutionPillNames'],
                buttonTitle: texts['solutionsCardButton'],
                path: SOFTWARE_SERVICES_PATH,
            },
            {
                title: texts['computerVisionSolutionTitle'],
                content: texts['computerVisionSolutionContent'],
                pillNames: texts['computerVisionSolutionPillNames'],
                buttonTitle: texts['solutionsCardButton'],
                path: COMPUTER_VISION_PATH,
            },
        ]
    }

    texts() {
        return this.props.texts
    }

    render() {
        return (
            <Service
                businessUnitDetailClassName="detailed-cryptography-services-presentation-section"
                texts={this.texts()}
                otherSolutions={this.otherSolutions()}
                otherProjects={[]}
            />
        )
    }
}

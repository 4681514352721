import React from 'react'
import PropTypes from 'prop-types'

export default class Button extends React.Component {
  title() {
    return this.props.title
  }

  buttonClassName() {
    return this.props.theme
  }

  disabled() {
    return this.props.disabled
  }

  onClick(event) {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(event);
    }
  }

  render() {
    return (
      <button
        disabled={this.disabled()}
        className={this.buttonClassName()}
        onClick={(event) => this.onClick(event)}
      >
        {this.title()}
      </button>
    )
  }
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf(['white', 'blue', 'white-pink']).isRequired,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  theme: 'white',
}

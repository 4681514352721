import React from 'react'
import Service from '../../components/Service'
import {
    CRYPTOGRAPHY_PATH,
    SOFTWARE_SERVICES_PATH,
    TOYOTA_PATH,
} from '../../routes'
import thirdProjectOverlayImage from '../../media/overlay_third_project.webp'

export default class ComputerVisionServices extends React.Component {
    texts() {
        return this.props.texts
    }

    solutionsTexts() {
        return this.texts()['solutions']
    }

    otherSolutions() {
        const texts = this.solutionsTexts()
        return [
            {
                title: texts['softwareSolutionTitle'],
                content: texts['softwareSolutionContent'],
                pillNames: texts['softwareSolutionPillNames'],
                buttonTitle: texts['solutionsCardButton'],
                path: SOFTWARE_SERVICES_PATH,
            },
            {
                title: texts['cryptographySolutionTitle'],
                content: texts['cryptographySolutionContent'],
                pillNames: texts['cryptographySolutionPillNames'],
                buttonTitle: texts['solutionsCardButton'],
                path: CRYPTOGRAPHY_PATH,
            },
        ]
    }

    otherProjects() {
        const solutionsTexts = this.solutionsTexts()
        return [
            {
                title: solutionsTexts['solutionsToyotaProjectTitle'],
                content: solutionsTexts['solutionsToyotaProjectContent'],
                image: thirdProjectOverlayImage,
                path: TOYOTA_PATH,
                button: solutionsTexts['solutionsCardButton'],
            },
        ]
    }

    render() {
        return (
            <Service
                businessUnitDetailClassName="detailed-cv-services-presentation-section"
                texts={this.texts()}
                otherSolutions={this.otherSolutions()}
                otherProjects={this.otherProjects()}
            />
        )
    }
}

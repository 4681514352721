import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button'

export default class Modal extends React.Component {
    id() {
        return this.props.id
    }

    isOpen() {
        return this.props.isOpen
    }

    icon() {
        return this.props.icon
    }

    title() {
        return this.props.title
    }

    description() {
        return this.props.description
    }

    onClose() {
        return this.props.onClose()
    }

    buttonTitle() {
        return this.props.buttonTitle
    }

    componentDidMount() {
        window.addEventListener('mousedown', () => this.onClose())
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', () => this.onClose())
    }

    render() {
        if (!this.isOpen()) {
            return null
        }

        const description = this.description()
        const buttonTitle = this.buttonTitle()
        return (
            <div id={this.id()} className="modal">
                <div className="modal-content">
                    <img className="modal-icon" src={this.icon()} alt="Logo" />
                    <h4 className="modal-title">{this.title()}</h4>
                    {description && (
                        <p className="modal-description">{description}</p>
                    )}
                    {buttonTitle && (
                        <div className="modal-button">
                            <Button title={buttonTitle} theme="blue" />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

Modal.propTypes = {
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    buttonTitle: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

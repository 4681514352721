import React from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'

export default class AnimatedCard extends React.Component {
    title() {
        return this.props.title
    }

    description() {
        return this.props.description
    }

    animatedIcon() {
        return this.props.animatedIcon
    }

    render() {
        return (
            <div className="card">
                <div className="icon-column">
                    <Lottie
                        options={{ animationData: this.animatedIcon() }}
                        style={{ width: '210px', height: '180px' }}
                    />
                </div>
                <div className="text-column">
                    <h3 className="column-header">{this.title()}</h3>
                    <p className="column-description">{this.description()}</p>
                </div>
            </div>
        )
    }
}

AnimatedCard.propTypes = {
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    animatedIcon: PropTypes.any.isRequired
}

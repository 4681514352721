import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import UsCard from './UsCard'


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
        slidesToSlide: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        slidesToSlide: 1,
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        slidesToSlide: 1,
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        slidesToSlide: 1,
        items: 1
    }
}

export default function AboutUsCarousel({ cardsTexts, cardImages, cardThemes }) {
    return (
        <div className={'us-activities-carousel'}>
            <Carousel responsive={responsive}
                      style={{ width: '100%' }}
                      infinite={true}
                      autoPlay={true}
                      autoPlaySpeed={4000}
                      showDots={true}
                      renderDotsOutside={true}
                      removeArrowOnDeviceType={['mobile', 'tablet', 'desktop']}>
                {cardsTexts.map((cardText, index) => (
                    <UsCard
                        key={index}
                        text={cardText}
                        image={cardImages[index]}
                        theme={cardThemes[index]}
                    />
                ))}
            </Carousel>
        </div>
    )
}

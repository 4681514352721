import React from 'react'
import Project from '../../components/Project'
import buenbitDesktopImage from '../../media/clients/buenbit-desktop-project.webp'
import secondProjectOverlayImage from '../../media/overlay_second_project.webp'
import thirdProjectOverlayImage from '../../media/overlay_third_project.webp'
import {
    FINAER_PATH,
    NEXT_ROLL_PATH,
    SOFTWARE_SERVICES_PATH, TOYOTA_PATH,
} from '../../routes'

export default class BuenbitProject extends React.Component {
    texts() {
        return this.props.texts
    }

    image() {
        return this.props.image
    }

    solutionsTexts() {
        return this.texts()['solutions']
    }

    otherProjects() {
        return [
            {
                title: this.solutionsTexts()['solutionsFinaerProjectTitle'],
                content: this.solutionsTexts()['solutionsFinaerProjectContent'],
                image: secondProjectOverlayImage,
                path: FINAER_PATH,
                button: this.solutionsTexts()['solutionsCardButton'],
            },
            {
                title: this.solutionsTexts()['solutionsNextRollProjectTitle'],
                content:
                    this.solutionsTexts()['solutionsNextRollProjectContent'],
                image: thirdProjectOverlayImage,
                path: NEXT_ROLL_PATH,
                button: this.solutionsTexts()['solutionsCardButton'],
            },
            {
                image: thirdProjectOverlayImage,
                title: this.solutionsTexts()['solutionsToyotaProjectTitle'],
                content: this.solutionsTexts()['solutionsToyotaProjectContent'],
                path: TOYOTA_PATH,
                button: this.solutionsTexts()['solutionsProjectButton'],
            },
        ]
    }

    render() {
        return (
            <Project
                texts={this.texts()}
                image={this.image()}
                projectImg={buenbitDesktopImage}
                otherProjects={this.otherProjects()}
                projectAreaPath={SOFTWARE_SERVICES_PATH}
            />
        )
    }
}

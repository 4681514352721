import React from 'react'
import Button from './Button'
import FormTextInput from './form/FormTextInput'
import FormBigTextInput from './form/FormBigTextInput'
import PropTypes from 'prop-types'
import Modal from './Modal'
import * as emailjs from '@emailjs/browser'
import thankYouIcon from '../media/icons/thank-you.svg'
import errorIcon from '../media/icons/error.svg'
import isEmailValid from "../helpers/utils";
import ReCAPTCHA from "react-google-recaptcha";

export default class RequestToJoinUs extends React.Component {
  NAME_FIELD = 'name'
  CONTACT_FIELD = 'contact'
  ABOUT_ME_LINK_FIELD = 'aboutMeLink'
  FREE_MESSAGE_TEXT_FIELD = 'freeMessageText'

  constructor(props) {
    super(props)
    this.state = {
      [this.NAME_FIELD]: '',
      [this.CONTACT_FIELD]: '',
      [this.ABOUT_ME_LINK_FIELD]: '',
      [this.FREE_MESSAGE_TEXT_FIELD]: '',
      modalTitle: '',
      modalIcon: '',
      modalDescription: '',
      modalButtonTitle: '',
      isCaptchaValid: false,
      isModalOpen: false,
      sendingEmail: false,
    }
    this._refCaptcha = React.createRef();
  }

  isFormValid() {
    return this.state[this.NAME_FIELD]
      && this.state[this.CONTACT_FIELD]
      && this.state[this.FREE_MESSAGE_TEXT_FIELD] &&
      isEmailValid(this.state[this.CONTACT_FIELD]) &&
      this.state.isCaptchaValid
  }

  successModalStateFor(texts) {
    return {
      modalTitle: texts['formSuccessTitle'],
      modalIcon: thankYouIcon,
      modalDescription: texts['formSuccessDescription'],
      modalButtonTitle: '',
      isModalOpen: true,
    }
  }

  errorModalStateFor(texts) {
    return {
      modalTitle: texts['formErrorTitle'],
      modalIcon: errorIcon,
      modalDescription: '',
      modalButtonTitle: texts['formErrorButton'],
      isModalOpen: true,
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({sendingEmail: true})

    const texts = this.texts();
    const token = this._refCaptcha.current.getValue();

    const params = {
      'name': this.state[this.NAME_FIELD],
      'contact': this.state[this.CONTACT_FIELD],
      'aboutMeLink': this.state[this.ABOUT_ME_LINK_FIELD],
      'freeMessageText': this.state[this.FREE_MESSAGE_TEXT_FIELD],
      'g-recaptcha-response': token,
    };

    emailjs
      .send(
        'service_h1vq2gr',
        'template_6nj6vai',
        params,
        {publicKey: '1Tqjnnmcy-mu4IHEA'},
      )
      .then(
        () => {
          this.setState(this.successModalStateFor(texts))
        },
        () => {
          this.setState(this.errorModalStateFor(texts))
        },
      ).finally(
      () => {
        this._refCaptcha.current.reset();
        this.setState({isCaptchaValid: false, sendingEmail: false})
      }
    )
  }

  handleInputChange(id, value) {
    this.setState({[id]: value})
  }

  texts() {
    return this.props.texts
  }

  modalTitle() {
    return this.state.modalTitle
  }

  isModalOpen() {
    return this.state.isModalOpen
  }

  modalDescription() {
    return this.state.modalDescription
  }

  modalButtonTitle() {
    return this.state.modalButtonTitle
  }

  modalIcon() {
    return this.state.modalIcon
  }

  handleModalClose() {
    this.setState({isModalOpen: false})
  }

  render() {
    const texts = this.texts()

    return (
      <div className="request-to-join-us-container-wrapper">
        <Modal
          id="request-to-join-modal"
          icon={this.modalIcon()}
          title={this.modalTitle()}
          isOpen={this.isModalOpen()}
          description={this.modalDescription()}
          buttonTitle={this.modalButtonTitle()}
          onClose={() => this.handleModalClose()}
        />
        <div className="request-to-join-us-container">
          <h3 className="request-to-join-title">
            {texts['formTitle']}
          </h3>
          <p className="request-to-join-description">
            {texts['formDescription']}
          </p>
          <form className="request-to-join-us-form">
            <FormTextInput
              id={this.NAME_FIELD}
              label={texts['formNameFilling']}
              placeholder={texts['formNamePlaceholder']}
              onChange={(value) =>
                this.handleInputChange(this.NAME_FIELD, value)
              }
            />
            <FormTextInput
              id={this.CONTACT_FIELD}
              label={texts['formEmailFilling']}
              placeholder={texts['formEmailPlaceholder']}
              onChange={(value) =>
                this.handleInputChange(
                  this.CONTACT_FIELD,
                  value,
                )
              }
            />
            <FormTextInput
              id={this.ABOUT_ME_LINK_FIELD}
              label={texts['formMoreAboutMeLink']}
              placeholder={texts['formMoreAboutMePlaceholder']}
              onChange={(value) =>
                this.handleInputChange(
                  this.ABOUT_ME_LINK_FIELD,
                  value,
                )
              }
            />
            <FormBigTextInput
              id={this.FREE_MESSAGE_TEXT_FIELD}
              label={texts['formMoreComments']}
              placeholder={texts['formMoreCommentsPlaceholder']}
              onChange={(value) =>
                this.handleInputChange(
                  this.FREE_MESSAGE_TEXT_FIELD,
                  value,
                )
              }
            />
            <ReCAPTCHA ref={this._refCaptcha}
                       onChange={() => this.setState({isCaptchaValid: true})}
                       sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}/>
            <div className="join-us-container">
              {/*<FileInput*/}
              {/*    title={texts['formUploadResume']}*/}
              {/*    onClick={(event) => this.handleSubmit(event)}*/}
              {/*/>*/}
              <Button
                title={this.state.sendingEmail ? texts['formButtonSending'] : texts['formButton']}
                disabled={!this.isFormValid() || this.state.sendingEmail}
                theme="blue"
                onClick={(event) => this.handleSubmit(event)}
              />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

RequestToJoinUs.propTypes = {
  texts: PropTypes.any.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'

export default class LanguageToggle extends React.Component {
    LANGUAGES = {
        ES: 'ES',
        EN: 'EN',
    }

    ES_CLASSNAMES = {
        [this.LANGUAGES.ES]: 'es',
        [this.LANGUAGES.EN]: 'es disabled',
    }

    EN_CLASSNAMES = {
        [this.LANGUAGES.ES]: 'en disabled',
        [this.LANGUAGES.EN]: 'en ',
    }

    OPPOSITE_LANGUAGE = {
        [this.LANGUAGES.ES]: this.LANGUAGES.EN,
        [this.LANGUAGES.EN]: this.LANGUAGES.ES,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedLanguage: this.LANGUAGES.ES,
        }
    }

    selectedLanguage() {
        return this.state.selectedLanguage
    }

    onChange(language) {
        return this.props.onChange(language)
    }

    handleOnClick() {
        const newLanguageSelection =
            this.OPPOSITE_LANGUAGE[this.selectedLanguage()]
        this.setState({ selectedLanguage: newLanguageSelection })
        return this.onChange(newLanguageSelection)
    }

    render() {
        const esClassName = this.ES_CLASSNAMES[this.selectedLanguage()]
        const enClassName = this.EN_CLASSNAMES[this.selectedLanguage()]

        return (
            <div className="language-toggle">
                <input
                    id="toggle"
                    className="toggle"
                    type="checkbox"
                    onClick={() => this.handleOnClick()}
                />
                <label htmlFor="toggle"></label>
                <span className={esClassName}>{this.LANGUAGES.ES}</span>
                <span className={enClassName}>{this.LANGUAGES.EN}</span>
            </div>
        )
    }
}

LanguageToggle.propTypes = {
    onChange: PropTypes.func.isRequired,
}

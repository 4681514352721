import React from 'react'
import PropTypes from 'prop-types'

export default class FormTextInput extends React.Component {
  id() {
    return this.props.id
  }

  label() {
    return this.props.label
  }

  placeholder() {
    return this.props.placeholder
  }

  onChange(value) {
    return this.props.onChange(value)
  }

  render() {
    const id = this.id()
    return (
      <div className="text-input-container">
        <label className="form-label" htmlFor={id}>
          {this.label()}
        </label>
        <input
          type="text"
          id={id}
          name={id}
          placeholder={this.placeholder()}
          className="form-text-input"
          onChange={(event) => this.onChange(event.target.value)}
        />
      </div>
    )
  }
}

FormTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

FormTextInput.defaultProps = {
  placeholder: '',
}

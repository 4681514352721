import ProjectCard from './ProjectCard'

import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const responsive = (amountOfItems) => {
    let hasOneItem = amountOfItems === 1
    return {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            slidesToSlide: 1,
            items: hasOneItem ? 1 : 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1720 },
            slidesToSlide: 1,
            items: hasOneItem ? 1 : 3
        },
        tablet: {
            breakpoint: { max: 1720, min: 700 },
            slidesToSlide: 1,
            items: hasOneItem ? 1 : 2
        },
        mobile: {
            breakpoint: { max: 700, min: 0 },
            slidesToSlide: 1,
            items: 1
        }
    }
}

export default class FeaturedProjects extends React.Component {

    title() {
        return this.props.title
    }

    typeOfView() {
        return this.props.typeOfView
    }

    render() {
        const projectsToRender = this.projects()
        const backgroundClass =
            this.typeOfView() === 'services'
                ? 'featured-projects-section'
                : 'other-solutions-section'

        if (!projectsToRender || projectsToRender.length === 0) {
            return <div className={backgroundClass}></div>
        }

        return (
            <div className={backgroundClass}>
                <div className="title-container">
                    <h3 className="featured-projects-section-title">{this.title()}</h3>
                </div>
                <div style={{ width: '100%' }}>
                    <Carousel responsive={responsive(projectsToRender.length)}
                              scrollable={true}
                              showDots={this._mustShowDots(projectsToRender)}
                              slidesToSlide={1}
                              draggable={true}
                              infinite={this._mustShowDots(projectsToRender)}
                              removeArrowOnDeviceType={['mobile', 'tablet', 'desktop', 'superLargeDesktop']}>
                        {
                            projectsToRender.map((project, index) => this.projectCardComponent(project, index))
                        }
                    </Carousel>
                </div>
            </div>
        )
    }

    _mustShowDots(projectsToRender) {
        return projectsToRender.length > responsive(projectsToRender.length)[this._currentDeviceType()].items
    }

    _currentDeviceType() {
        let width = window.innerWidth
        let deviceType = 'unknown'
        Object.keys(responsive(1)).forEach(key => {
            const { min, max } = responsive(1)[key].breakpoint
            if (width >= min && width <= max) {
                deviceType = key
            }
        })
        return deviceType
    }

    projects() {
        return this.props.projects
    }

    projectCardComponent(project, index) {
        return (
            <div className="project-card-and-image-wrapper-container prevent-select" key={index}>
                <div className="project-card-and-image-wrapper"
                     key={index}>
                    <img className="project-image-overlay"
                         src={project.image}
                         alt={`${index} project`}
                    />

                    <div className="project-description-card">
                        <ProjectCard
                            image={project.image}
                            title={project.title}
                            content={project.content}
                            path={project.path}
                            buttonTitle={project.button}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

FeaturedProjects.propTypes = {
    title: PropTypes.string.isRequired,
    typeOfView: PropTypes.oneOf(['services', 'projects']).isRequired,
    projects: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.any,
            title: PropTypes.string,
            content: PropTypes.string,
            path: PropTypes.string,
            button: PropTypes.string
        })
    )
}

import React from "react";
import SolutionsCard from "./SolutionsCard";

export default class KnowOtherSolutions extends React.Component {

  render() {
    const solutionsToRender = this._solutions()

    return (
      <div className="background-other-solutions-section">
        <div className="know-other-solutions-container">
          <div className="other-solution-title-wrapper">
            <h3 className="other-solutions-title">
              {this.props.title}
            </h3>
          </div>
          <div className="card-solution-container">
            {solutionsToRender.map((solution, index) => (
              <SolutionsCard
                key={index}
                title={solution.title}
                content={solution.content}
                pillNames={solution.pillNames}
                buttonTitle={solution.buttonTitle}
                path={solution.path}
                extraClassName="project-card-max-width"
                theme="violet"
              ></SolutionsCard>
            ))}
          </div>
        </div>
      </div>
    )
  }

  _solutions() {
    return this.props.solutions
  }

}

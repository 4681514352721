import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
        slidesToSlide: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1450 },
        slidesToSlide: 1,
        items: 4,
        partialVisibilityGutter: 40
    },
    tabletXl: {
        breakpoint: { max: 1450, min: 1280 },
        slidesToSlide: 1,
        items: 3
    },
    tablet: {
        breakpoint: { max: 1280, min: 520 },
        slidesToSlide: 1,
        items: 2
    },
    mobile: {
        breakpoint: { max: 520, min: 0 },
        slidesToSlide: 1,
        items: 1
    }
}

export default class PartnerCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hoverIndex: null,
            shuffledPartners: []
        }
    }

    partnerData() {
        return this.props.partnerData
    }

    handleMouseOver = (rowNumber, index) => {
        this.setState({ hoverIndex: `${rowNumber}-${index}` })
    }

    isElementHovered(listNumber, index) {
        return this.state.hoverIndex === `${listNumber}-${index}`
    }

    handleMouseOut = () => {
        this.setState({ hoverIndex: null })
    }

    componentDidMount() {
        const shuffledPartners = this.shuffleArray(this.partnerData())
        this.setState({ shuffledPartners })
    }

    hasStateChanged(nextState) {
        const hasShuffledPartnersChanged = JSON.stringify(this.state.shuffledPartners) !== JSON.stringify(nextState.shuffledPartners)
        const hasHoverIndexChanged = this.state.hoverIndex !== nextState.hoverIndex
        const hasSlideIndexChanged = this.state.slideIndex !== nextState.slideIndex
        return hasShuffledPartnersChanged || hasHoverIndexChanged || hasSlideIndexChanged
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.hasStateChanged(nextState)) {
            return true
        }
        const hasPartnerDataChanged = JSON.stringify(this.partnerData()) !== JSON.stringify(nextProps.partnerData)
        if (hasPartnerDataChanged) {
            this.updateStateWithReorderedNewPartnersData(nextProps.partnerData)
            return true
        }

        return false
    }

    shuffleArray = (array) => {
        let arr = array.slice()
        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arr[i], arr[j]] = [arr[j], arr[i]]
        }
        return arr
    }

    updateStateWithReorderedNewPartnersData(newPartners) {
        const currentShuffledPartners = this.state.shuffledPartners
        let updatedShuffledPartners = []

        currentShuffledPartners.forEach(p => {
            const newPartner = newPartners.find(q => q.fullName === p.fullName)
            updatedShuffledPartners.push(newPartner)
        })

        this.setState({ shuffledPartners: updatedShuffledPartners })
    }

    amountOfPartners() {
        return this.state.shuffledPartners.length
    }

    drawPartnersInCarousel(partners) {
        let result = []
        for (let currentPartnerIndex = 0; currentPartnerIndex < partners.length; currentPartnerIndex += 2) {
            let partner = partners[currentPartnerIndex]

            let nextPartnerIndex = currentPartnerIndex + 1
            let nextPartner = {
                'fullName': 'Eryk',
                'seriousRole': 'Mascota',
                'relaxedRole': 'Mascota',
                'seriousImage': '/static/eryk.webp',
                'relaxedImage': '/static/eryk.webp'
            }
            if (partners.length > nextPartnerIndex) {
                nextPartner = partners[nextPartnerIndex]
            }

            result.push(
                <div className="partners-column" key={currentPartnerIndex}>
                    {this.drawPartnerLogo(1, currentPartnerIndex, partner)}
                    {this.drawPartnerLogo(2, nextPartnerIndex, nextPartner)}
                </div>
            )
        }
        return result
    }

    drawPartnerLogo(rowNumber, partnerIndex, partner) {
        return <div className="partner-logo-item-container prevent-select">
            <div className="partner-logo-item">
                {/*Preload images done awfully*/}
                <div style={{ backgroundImage: `url(${partner.relaxedImage})`, visibility: 'hidden' }}></div>
                <div
                    className="partner-logo"
                    style={{ backgroundImage: `url(${this.isElementHovered(rowNumber, partnerIndex) ? partner.relaxedImage : partner.seriousImage})` }}
                    onMouseOver={() => this.handleMouseOver(rowNumber, partnerIndex)}
                    onMouseOut={this.handleMouseOut}
                />
                <div className="gradient-overlay"></div>
                <div className="partner-logo-text"
                     onMouseOver={() => this.handleMouseOver(rowNumber, partnerIndex)}
                     onMouseOut={this.handleMouseOut}>
                    <h3 className="partner-full-name">
                        {partner.fullName}
                    </h3>
                    <p className="partner-serious-role">
                        {this.isElementHovered(rowNumber, partnerIndex) ? partner.relaxedRole : partner.seriousRole}
                    </p>
                </div>
            </div>
        </div>
    }

    renderCarouselFor(rowNumber, partners) {
        return (
            <Carousel responsive={responsive}
                      style={{ width: '100%' }}
                      infinite={true}
                      showDots={false}
                      autoPlay={true}
                      autoPlaySpeed={3000}
                      renderDotsOutside={false}
                      removeArrowOnDeviceType={['mobile', 'tablet', 'desktop']}>
                {
                    this.drawPartnersInCarousel(partners)
                }
            </Carousel>
        )
    }

    render() {
        const shuffledPartners = this.state.shuffledPartners.slice(0, this.amountOfPartners())
        return (
            <div className="horizontal-partners-carrousel">
                {this.renderCarouselFor(1, shuffledPartners)}
            </div>
        )
    }

}

PartnerCard
    .propTypes = {
    partnerData: PropTypes.array.isRequired
}

import React from 'react'
import PropTypes from 'prop-types'

export default class UsCard extends React.Component {
    text() {
        return this.props.text
    }

    image() {
        return this.props.image
    }

    theme() {
        return this.props.theme
    }

    render() {
        const usCardStyle = {
            backgroundColor: this.theme(),
        }
        return (
            <div className="us-card-wrapper-container prevent-select">
                <div className="us-card-wrapper" style={usCardStyle}>
                    <img
                        className="us-image-render"
                        src={this.image()}
                        alt="Eryx Team"
                    />
                    <div className="us-text-content-container">
                        <h3 className="us-text-content">
                            {this.text()}
                        </h3>
                    </div>
                </div>
            </div>
        )
    }
}

UsCard.propTypes = {
    image: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,
}

UsCard.defaultProps = {
    theme: 'blue',
}

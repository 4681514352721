import React from 'react'
import PropTypes from 'prop-types'

export default class WordsVerticalRotator extends React.Component {
    wordsList() {
        return this.props.wordsList
    }

    title() {
        return this.props.title
    }

    render() {
        const wordsList = this.wordsList();
        const itemClassName = `dynamic-text-item ${wordsList.length < 6 ? 'short-list' : 'long-list'}`
        return (
            <div className="words-vertical-rotator">
                <p className="static-text">{this.title()}</p>
                <ul className="dynamic-text">
                    {wordsList.map((text) => (
                        <li className={itemClassName} key={text}>
                            {text}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}

WordsVerticalRotator.propTypes = {
    title: PropTypes.string.isRequired,
    wordsList: PropTypes.arrayOf(PropTypes.string).isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import leftArrow from '../media/left-arrow.svg'
import { Link } from 'react-router-dom'

export default class NavigationButton extends React.Component {
    title() {
        return this.props.title
    }

    arrowDirection() {
        return this.props.arrowDirection
    }

    url() {
        return this.props.url
    }

    openInNewTab() {
        return this.props.openInNewTab;
    }

    render() {
        const arrowDirection = this.arrowDirection()
        const navigationButtonClassName = `navigation-button ${arrowDirection}`
        const arrowClassName = `${arrowDirection}-arrow`
        const altText = `${arrowDirection} Arrow`
        const buttonNameClassName = `button-name ${arrowDirection} `
        const target = this.openInNewTab() ? '_blank' : '_self'

        return (
            <Link to={this.url()} target={target}>
                <div className={navigationButtonClassName}>
                    <img
                        className={arrowClassName}
                        src={leftArrow}
                        alt={altText}
                    ></img>
                    <p className={buttonNameClassName}>{this.title()}</p>
                </div>
            </Link>
        )
    }
}

NavigationButton.propTypes = {
    title: PropTypes.string.isRequired,
    openInNewTab: PropTypes.bool,
    arrowDirection: PropTypes.oneOf(['left', 'right']).isRequired,
    url: PropTypes.string.isRequired,
}

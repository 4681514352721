import React from 'react'
import instagramIcon from '../media/social-media/icon-instagram.svg'
import facebookIcon from '../media/social-media/icon-facebook.svg'
import linkedinIcon from '../media/social-media/icon-linkedin.svg'
import xIcon from '../media/social-media/icon-x.svg'
import youtubeIcon from '../media/social-media/icon-youtube.png'
import mediumIcon from '../media/social-media/icon-medium.svg'
import eryxLogo from '../media/eryx-logo.svg'
import PropTypes from 'prop-types'

export default class Footer extends React.Component {
    texts() {
        return this.props.texts
    }
    copyrightText() {
        return this.texts()['copyright']
    }

    linkedinUrl() {
        return 'https://www.linkedin.com/company/eryx-soluciones/'
    }

    instagramUrl() {
        return 'https://www.instagram.com/eryxcoop/'
    }

    facebookUrl() {
        return 'https://www.facebook.com/eryxcoop/'
    }

    xUrl() {
        return 'https://twitter.com/eryxcoop/'
    }

    youtubeUrl() {
        return 'https://www.youtube.com/@eryxcoop8920/'
    }

    mediumUrl() {
        return 'https://medium.com/eryxcoop/'
    }

    renderSocialMedia(title, url, icon) {
        return (
            <a className={title} href={url} title={title} target="_blank" rel="noreferrer">
                <img src={icon} alt={title} />
            </a>
        )
    }

    render() {
        return (
            <div className="footer">
                <div className="company-name-column">
                    <img
                        className="column-header"
                        src={eryxLogo}
                        alt="Eryx Logo"
                    />
                </div>
                <div className="social-media-column">
                    <div className="icons">
                        {this.renderSocialMedia(
                            'instagram',
                            this.instagramUrl(),
                            instagramIcon,
                        )}
                        {this.renderSocialMedia(
                            'linkedin',
                            this.linkedinUrl(),
                            linkedinIcon,
                        )}
                        {this.renderSocialMedia(
                            'medium',
                            this.mediumUrl(),
                            mediumIcon,
                        )}
                        {this.renderSocialMedia(
                            'x',
                            this.xUrl(),
                            xIcon,
                        )}
                        {this.renderSocialMedia(
                            'facebook',
                            this.facebookUrl(),
                            facebookIcon,
                        )}
                        {this.renderSocialMedia(
                            'youtube',
                            this.youtubeUrl(),
                            youtubeIcon,
                        )}
                    </div>
                    <div className="copyright-text">
                        <p>{this.copyrightText()}</p>
                    </div>
                </div>
            </div>
        )
    }
}

Footer.propTypes = {
    texts: PropTypes.any.isRequired,
}

import React from 'react'
import SolutionsCard from './SolutionsCard'
import PropTypes from 'prop-types'

export default class ProjectCard extends React.Component {
    image() {
        return this.props.image
    }

    title() {
        return this.props.title
    }

    content() {
        return this.props.content
    }

    path() {
        return this.props.path
    }

    buttonTitle() {
        return this.props.buttonTitle
    }

    render() {
        return (
            <SolutionsCard
                path={this.path()}
                buttonTitle={this.buttonTitle()}
                title={this.title()}
                pillNames={[]}
                content={this.content()}
            />
        )
    }
}

ProjectCard.propTypes = {
    image: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
}

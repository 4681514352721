import React from 'react'
import PropTypes from 'prop-types'
import Pill from './Pill'
import plusIcon from '../media/icons/plus.svg'
import {Link} from 'react-router-dom'

export default class SolutionsCard extends React.Component {
  title() {
    return this.props.title
  }

  pillNames() {
    return this.props.pillNames
  }

  content() {
    return this.props.content
  }

  theme() {
    return this.props.theme
  }

  buttonTitle() {
    return this.props.buttonTitle
  }

  path() {
    return this.props.path
  }

  render() {
    const cardClassName = `solutions-card ` + this.theme()
    const classes = cardClassName + (this.props.extraClassName ? ` ${this.props.extraClassName}` : '')

    return (
      <div className={classes}>
        <h3 className="solutions-card-text">{this.title()}</h3>

        <div className="solutions-pills">
          {this.pillNames().map((pillName) => (
            <Pill name={pillName} key={pillName}/>
          ))}
        </div>
        <p className="solutions-card-text">{this.content()}</p>

        <div className="solutions-button-container">
          <Link to={this.path()} draggable={false}>
            <button className="solutions-button">
                          <span className="solutions-button-text">
                              {this.buttonTitle()}
                          </span>
              <img
                className="solutions-button-icon"
                src={plusIcon}
                alt="Plus icon"
              />
            </button>
          </Link>
        </div>
      </div>
    )
  }
}

SolutionsCard.propTypes = {
  title: PropTypes.string.isRequired,
  completeHeight: PropTypes.bool,
  pillNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  content: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['blue', 'violet']).isRequired,
  buttonTitle: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  extraClassName: PropTypes.string,
}

SolutionsCard.defaultProps = {
  theme: 'blue'
}

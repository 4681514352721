import React from 'react'
import firstEryxTeamPhoto from '../media/eryx-team/eryx_team_1.webp'
import secondEryxTeamPhoto from '../media/eryx-team/eryx_team_2.webp'
import thirdEryxTeamPhoto from '../media/eryx-team/eryx_team_3.webp'
import fourthEryxTeamPhoto from '../media/eryx-team/eryx_team_4.webp'
import fifthEryxTeamPhoto from '../media/eryx-team/eryx_team_5.webp'
import PartnerCard from '../components/PartnerCard'
import RequestToJoinUs from '../components/RequestToJoinUs'
import AboutUsCarousel from "../components/AboutUsCarousel";


export default class Us extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slideIndex: 1,
    }
  }

  texts() {
    return this.props.texts
  }

  partners() {
    return this.props.partners
  }

  render() {
    return (
      <div className="us-roles-container">
        {this._renderUsPresentationSection()}
        {this._renderUsPartnersRolesSection()}
        {this._renderRequestToJoinSection()}
      </div>
    )
  }

  _renderUsPresentationSection() {
    const texts = this._presentationTexts()
    return (
      <div className="us-card-container">
        <div className="top-grey-squares">
          <div className="top-right-grey-square"/>
          <div className="top-right-grey-square-2"/>
        </div>
        <div className="top-blue-semicircle-and-circle">
          <div className="top-transparent-circle"/>
          <div className="top-blue-semicircle"/>
        </div>
        <div className="bottom-left-circle"/>
        <div className="bottom-left-small-blue-square"/>
        <div className="us-card-container-row">
          <div className="us-info">
            <h2 className="us-title">
              {texts['title']}
              <br/>
            </h2>
            <p className="us-description">{texts['description']}</p>
          </div>
          <AboutUsCarousel cardsTexts={texts['cardsTexts']} cardImages={this._cardImages()}
                           cardThemes={this._cardThemes()}/>
        </div>
      </div>
    )
  }

  _renderUsPartnersRolesSection() {
    return (
      <div className="us-partner-cards-container">
        <h2 className="us-roles-title">
          {this.texts()['usPhotos']['title']}
        </h2>
        <PartnerCard partnerData={this.partners()['data']}/>
      </div>
    )
  }

  _renderRequestToJoinSection() {
    return (
      <div className="request-to-join-us-section">
        <RequestToJoinUs texts={this._requestToJoinTexts()}/>
      </div>
    )
  }

  _cardImages() {
    return [
      firstEryxTeamPhoto,
      secondEryxTeamPhoto,
      thirdEryxTeamPhoto,
      fourthEryxTeamPhoto,
      fifthEryxTeamPhoto,
    ]
  }

  _cardThemes() {
    return ['#293DED', '#5A74FC', '#756D9E', '#FF6A87', '#5A74FC']
  }

  _presentationTexts() {
    return this.texts()['usPresentation']
  }

  _requestToJoinTexts() {
    return this.texts()['requestToJoinUs']
  }
}

import React from 'react'
import finaerDesktopImage from '../../media/clients/finaer-desktop-project.webp'
import {BUENBIT_PATH, NEXT_ROLL_PATH, SOFTWARE_SERVICES_PATH, TOYOTA_PATH} from '../../routes'
import firstProjectOverlayImage from '../../media/overlay_first_project.webp'
import thirdProjectOverlayImage from '../../media/overlay_third_project.webp'
import Project from '../../components/Project'

export default class FinaerProject extends React.Component {
    texts() {
        return this.props.texts
    }

    solutionsTexts() {
        return this.texts()['solutions']
    }

    image() {
        return this.props.image
    }

    otherProjects() {
        const texts = this.solutionsTexts()
        return [
            {
                title: texts['solutionsBuenbitProjectTitle'],
                content: texts['solutionsBuenbitProjectContent'],
                image: firstProjectOverlayImage,
                path: BUENBIT_PATH,
                button: texts['solutionsCardButton'],
            },
            {
                title: texts['solutionsNextRollProjectTitle'],
                content: texts['solutionsNextRollProjectContent'],
                image: thirdProjectOverlayImage,
                path: NEXT_ROLL_PATH,
                button: texts['solutionsCardButton'],
            },
            {
                image: thirdProjectOverlayImage,
                title: texts['solutionsToyotaProjectTitle'],
                content: texts['solutionsToyotaProjectContent'],
                path: TOYOTA_PATH,
                button: texts['solutionsProjectButton'],
            },
        ]
    }

    render() {
        return (
            <Project
                texts={this.texts()}
                image={this.image()}
                projectImg={finaerDesktopImage}
                otherProjects={this.otherProjects()}
                projectAreaPath={SOFTWARE_SERVICES_PATH}
            />
        )
    }
}

import React from 'react';
import Pill from './Pill';
import FeaturedProjects from './FeaturedProjects';
import NavigationButton from './NavigationButton';
import PropTypes from 'prop-types';

export default class Project extends React.Component {
    texts() {
        return this.props.texts
    }

    image() {
        return this.props.projectImg
    }

    otherProjects() {
        return this.props.otherProjects
    }

    mainSectionPath() {
        return this.props.projectAreaPath
    }

    _renderOtherProjectsSection() {
        const projects = this.otherProjects()
        if (!projects || projects.length === 0) {
            return null
        }
        return (
            <FeaturedProjects
                title={this.texts()['otherProjectsTitle']}
                typeOfView="projects"
                projects={projects}
            />
        )
    }
    _renderProjectSection() {
        const texts = this._projectTexts()

        return (
            <div className="project-info-section-container">
                <div className={"project-info-section"}>
                    <div className="project-key-points">
                        <NavigationButton
                          openInNewTab={false}
                          title={texts['previousSectionTitle']}
                          arrowDirection="left"
                          url={this.mainSectionPath()}
                        />
                        <h2 className="project-title">{texts['clientName']}</h2>
                        <Pill
                          className="project-area-of-services"
                          name={texts['areaOfServices']}
                        />
                    </div>
                    <div className="project-details">
                        <div className="project-history">
                            <p className="history-details">
                                {texts['firstProjectDescription']}
                            </p>
                            <p className="history-details">
                                {texts['secondProjectDescription']}
                            </p>

                            <div className="history-main-points">
                                <h3 className="main-points-title">
                                    {texts['questionWhatWeDid']}
                                </h3>
                                <ul className="main-points-list">
                                    {texts['achievements'].map((achievement, index) => (
                                      <li key={index}>{achievement}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _projectTexts() {
        return this.texts()['project']
    }

    render() {
        return (

          <div className="project-container">
              <div>
                  <div className="border-extension-blue">
                  </div>
                  <div className="project-image-container">
                      <div className="project-image-inner-container">
                          <img
                            className="project-image"
                            src={this.image()}
                            alt="Project"
                          />
                      </div>
                  </div>
                  <div className="border-extension-grey">
                  </div>
              </div>
              {this._renderProjectSection()}
              {this._renderOtherProjectsSection()}
          </div>
        )
    }
}

Project.propTypes = {
    texts: PropTypes.any.isRequired,
    projectImg: PropTypes.any.isRequired,
    otherProjects: PropTypes.arrayOf(
      PropTypes.shape({
          image: PropTypes.any,
          title: PropTypes.string,
          content: PropTypes.string,
          path: PropTypes.string,
          button: PropTypes.string,
      }),
    ),
    projectAreaPath: PropTypes.string.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'

export default class Pill extends React.Component {
    name() {
        return this.props.name
    }

    render() {
        return <div className="pill">{this.name()}</div>
    }
}

Pill.propTypes = {
    name: PropTypes.string.isRequired,
}

import React from 'react'
import logoAgencia from '../media/clients/logo-agencia.webp'
import logoInta from '../media/clients/logo-inta.webp'
import logoUnicen from '../media/clients/logo-unicen.webp'
import logoBuenbit from '../media/clients/logo-buenbit.webp'
import logoFinaer from '../media/clients/logo-finaer.webp'
import logoChemo from '../media/clients/logo-chemo.webp'
import logoEGLC from '../media/clients/logo-eglc.webp'
import logoIneco from '../media/clients/logo-ineco.webp'
import logoMincyt from '../media/clients/logo-mincyt.webp'
import logoNextroll from '../media/clients/logo-nextroll.webp'
import logoSapore from '../media/clients/logo-sapore.webp'
import logoUNAHUR from '../media/clients/logo-UNAHUR.webp'
import logoUNMDP from '../media/clients/logo-UNMDP.webp'
import logoZoetis from '../media/clients/logo-zoetis.webp'
import logoFacttic from '../media/clients/facttic-logo.webp'
import logoPatio from '../media/clients/logo-patio.webp'
import logoAdecoagro from '../media/clients/logo-adecoagro.webp'

export default class LogosCarrousel extends React.Component {
    render() {
        return (
            <div className="horizontal-carrousel">
                <div className="logos-container">
                    <img
                        className="logo"
                        src={logoAgencia}
                        alt="Logo Agendia"
                    />
                    <img
                        className="logo"
                        src={logoFacttic}
                        alt="Logo Facttic"
                    />
                    <img
                        className="logo"
                        src={logoPatio}
                        alt="Logo Patio"
                    />
                    <img
                        className="logo"
                        src={logoBuenbit}
                        alt="Logo Buenbit"
                    />
                    <img
                        className="logo"
                        src={logoAdecoagro}
                        alt="Logo Adecoagro"
                    />
                    <img className="logo" src={logoFinaer} alt="Logo Finaer"/>
                    <img className="logo" src={logoUnicen} alt="Logo Unicen"/>
                    <img className="logo" src={logoChemo} alt="Logo Chemo"/>
                    <img className="logo" src={logoEGLC} alt="Logo El Gato y la caja"/>
                    <img className="logo" src={logoIneco} alt="Logo Ineco"/>
                    <img className="logo" src={logoMincyt} alt="Logo Mincyt"/>
                    <img className="logo" src={logoSapore} alt="Logo Sapore"/>
                    <img
                        className="logo"
                        src={logoNextroll}
                        alt="Logo NextRoll"
                    />
                    <img className="logo" src={logoUNAHUR} alt="Logo Unahur"/>
                    <img className="logo" src={logoUNMDP} alt="Logo Unmdp"/>
                    <img className="logo" src={logoZoetis} alt="Logo Zeotis"/>
                    <img className="logo" src={logoInta} alt="Logo Inta"/>
                    <img
                        className="logo"
                        src={logoAgencia}
                        alt="Logo Agendia"
                    />
                    <img
                        className="logo"
                        src={logoFacttic}
                        alt="Logo Facttic"
                    />
                    <img
                        className="logo"
                        src={logoPatio}
                        alt="Logo Patio"
                    />
                    <img
                        className="logo"
                        src={logoBuenbit}
                        alt="Logo Buenbit"
                    />
                    <img className="logo" src={logoFinaer} alt="Logo Finaer"/>
                    <img className="logo" src={logoUnicen} alt="Logo Unicen"/>
                    <img className="logo" src={logoChemo} alt="Logo Chemo"/>
                    <img className="logo" src={logoEGLC} alt="Logo El gato y la caja"/>
                    <img className="logo" src={logoIneco} alt="Logo Ineco"/>
                    <img className="logo" src={logoMincyt} alt="Logo Mincyt"/>
                    <img className="logo" src={logoSapore} alt="Logo Sapore"/>
                    <img
                        className="logo"
                        src={logoNextroll}
                        alt="Logo NextRoll"
                    />
                    <img className="logo" src={logoUNAHUR} alt="Logo Unahur"/>
                    <img className="logo" src={logoUNMDP} alt="Logo Unmdp"/>
                    <img className="logo" src={logoZoetis} alt="Logo Zoetis"/>
                    <img className="logo" src={logoInta} alt="Logo Inta"/>
                </div>
            </div>
        )
    }
}

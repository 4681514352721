import React from 'react'
import logoEryx from '../media/eryx-logo.svg'
import iconBurger from '../media/icons/burger.svg'
import iconCross from '../media/icons/cross.svg'
import { Link } from 'react-router-dom'
import SectionLink from './SectionLink'
import LanguageToggle from './LanguageToggle'
import PropTypes from 'prop-types'
import { CONTACT_PATH, HOME_PATH, SOLUTIONS_PATH, US_PATH } from '../routes'

export default class Navbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuOpened: false,
        }
    }

    isMenuOpened() {
        return this.state.isMenuOpened
    }

    texts() {
        return this.props.texts
    }

    handleMenuClick() {
        const isMenuOpened = this.isMenuOpened()
        this.setState({
            isMenuOpened: !isMenuOpened,
        })
    }

    onLanguageChange(language) {
        return this.props.onLanguageChange(language)
    }

    renderMenuItem(path, textsKey) {
        const texts = this.texts()
        return (
            <div className="menu-link">
                <SectionLink to={path} title={texts[textsKey]} onClick={() => this.handleMenuClick()}/>
            </div>
        )
    }

    render() {
        const menuOpened = this.isMenuOpened()
        const navbarClassName = menuOpened ? 'navbar active' : 'navbar'
        const menuIcon = menuOpened ? iconCross : iconBurger

        return (
            <nav className={navbarClassName}>
                <div className="navbar-container">
                    <Link
                        to={HOME_PATH}
                        onClick={() => {}}
                        className="navbar-logo-link"
                    >
                        <div className="navbar-logo-container">
                            <img
                                className="navbar-main-logo"
                                src={logoEryx}
                                alt="Logo Eryx"
                            />
                        </div>
                    </Link>

                    <div className="navbar-options">
                        <LanguageToggle
                            onChange={(language) =>
                                this.onLanguageChange(language)
                            }
                        />
                        <img
                            src={menuIcon}
                            alt="Icono hamburguesa"
                            onClick={(event) => this.handleMenuClick(event)}
                            className="navbar-burger-icon"
                        />
                    </div>
                </div>

                <div className="navbar-menu-container">
                    <div className="navbar-menu">
                        {this.renderMenuItem(SOLUTIONS_PATH, 'solutionsTitle')}
                        {this.renderMenuItem(US_PATH, 'aboutUsTitle')}
                        {this.renderMenuItem(CONTACT_PATH, 'contactUsTitle')}
                    </div>
                    <div className="navbar-menu-image"/>
                </div>
            </nav>
        )
    }
}

Navbar.propTypes = {
    onLanguageChange: PropTypes.func.isRequired,
    texts: PropTypes.any.isRequired,
}

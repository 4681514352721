import React from 'react'
import { CONTACT_PATH } from '../routes'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import FeaturedProjects from './FeaturedProjects'
import KnowOtherSolutions from './KnowOtherSolutions'
import Pill from './Pill'
import Button from './Button'

export default class Service extends React.Component {
    businessUnitDetailClassName() {
        return this.props.businessUnitDetailClassName
    }

    keyWords() {
        return this._presentationTexts()['keyWords']
    }

    _renderBusinessUnitTitle() {
        const texts = this._presentationTexts()
        return (
            <div className={this.businessUnitDetailClassName()}>
                <div className="business-unit-title">
                    <h1 className="featured-business-units-title">
                        {texts['bigTitle']}
                    </h1>
                </div>
            </div>
        )
    }

    _renderBusinessUnitDetail() {
        const texts = this._presentationTexts()
        return (
            <div className="service-description-container">
                <div className="service-summary">
                    <h2 className="featured-business-units-small-title">
                        {texts['smallTitle']}
                    </h2>
                    <div className="key-words-pill-container">
                        {this.keyWords().map((keyword) => (
                            <Pill name={keyword} key={keyword} />
                        ))}
                    </div>
                    <div className="send-button-container">
                        <Link to={CONTACT_PATH}>
                            <Button
                                title={texts['requestButton']}
                                theme="white-pink"
                                onClick={() => this._handleSubmit()}
                            />
                        </Link>
                    </div>
                </div>

                <div className="large-service-description">
                    <p className="service-detailed-description">
                        {texts['longDescription']}
                    </p>
                </div>
            </div>
        )
    }

    _renderFeaturedProjects() {
        return (
            <FeaturedProjects
                title={this._texts()['highlightedProjectsTitle']}
                typeOfView="services"
                projects={this.otherProjects()}
            />
        )
    }

    otherProjects() {
        return this.props.otherProjects
    }

    otherSolutions() {
        return this.props.otherSolutions
    }

    _renderKnowOtherSolutions() {
        return <KnowOtherSolutions solutions={this.otherSolutions()} title={this._texts()['otherSolutionsTitle']}/>
    }

    _texts() {
        return this.props.texts
    }

    _presentationTexts() {
        return this._texts()['presentation']
    }

    _handleSubmit() {}

    render() {
        return (
            <div className="detailed-services-container">
                {this._renderBusinessUnitTitle()}
                {this._renderBusinessUnitDetail()}
                {this._renderFeaturedProjects()}
                {this._renderKnowOtherSolutions()}
            </div>
        )
    }
}

Service.propTypes = {
    businessUnitDetailClassName: PropTypes.string.isRequired,
    otherSolutions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            pillNames: PropTypes.arrayOf(PropTypes.string).isRequired,
            buttonTitle: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        }),
    ).isRequired,
    otherProjects: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            image: PropTypes.any.isRequired,
            button: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        }),
    ).isRequired,
}
